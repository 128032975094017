<template>
  <div class="header">
      <div class="title" @click="$emit('newTab','Splash')">glossi.fi</div>
      <div class="links">
        <div class="link" @click="$emit('newTab','Splash')">home</div>
        <div class="separator">|</div>
        <div class="link" @click="$emit('newTab','About')">about us</div>
        <div class="separator">|</div>
        <div class="link" @click="$emit('newTab','Contact')">contact</div>
          </div>
      <img class="nsf-logo" src="../assets/NSF.png">
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {

  }
}
</script>

<style>
.header{
    position: absolute;
    top: 0;
    left: 0;
    height: 15vh;
    width: 100vw;
    margin: 0;
    background-color: black;
}
.title{
    position: absolute;
    top: 7.5vh;
    left: 3vw;
    transform: translateY(-50%);
    font-size: 3vw;
    font-weight: bold;
    padding: .3vw;
    margin: .5vw;
    background-color: black;
}
.links{
    background-color: black;
    position: absolute;
    top: 7.5vh;
    transform: translateY(-50%);
    right: 10vw;
    display: flex;
    flex-direction: row;
}
.link,.separator{
    background-color: black;
    font-size: 1.4vw;
    padding: .3vw;
    margin: .5vw;
}
.link:hover,.title:hover{
    background-color: greenyellow;
    color: black;
}
.nsf-logo{
    width: 4vw;
    position: absolute;
    top: 7.5vh;
    right: 3vw;
    transform: translateY(-50%);
}
</style>
