<template>
  <Header v-on:newTab="changeTab"/>
  <component class="comp" :is="activeTab" />
  <Footer />
</template>

<script>
import Splash from './components/Splash.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import {defineAsyncComponent} from '@vue/runtime-core'

export default {
  name: 'App',
  components: {
    Splash,
    Header,
    Footer
  },
  data() {
    return {
      tab: 'Splash'
    }
  },
  computed: {
    activeTab() {
      console.log(this.tab)
      return defineAsyncComponent(() => import(`@/components/${this.tab}.vue`))
    },
  },
  methods: {
    changeTab(evt){
      this.tab = evt
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: greenyellow;
  font-family: 'Courier New', Courier, monospace;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
.comp{
  position: absolute;
  top: 15vh;
  left: 0;
  width: 100vw;
  height: 85vh;
  background-color: #141313;
  margin: 0;
}
</style>
