<template>
  <div class="hello">
Glossifi Inc is a software product and services company.
<br>
<br>
We built a blockchain analytics service for Ethereum!
<br>
<br>
And thanks to a grant from NSF, we’re currently working on a <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2052118&HistoricalAwards=false">new type of distributed webservice</a>
<br>
<br>
We're also hiring two developers--
<br>
<br>
<a href="https://www.linkedin.com/jobs/view/2872387589/?refId=O3EjYFjDRl6CV1r%2FF78zAw%3D%3D">Here's a link to our job listing on linkedin</a>
<br>
<br>
<a href = "mailto:donald.josey@gmail.com?subject = Feedback&body = Message">
Contact us for more info!
</a>


    
  </div>
</template>

<script>
export default {
  name: 'Splash',

}
</script>

<style scoped>

</style>
